import { render, staticRenderFns } from "./RuleListItems.vue?vue&type=template&id=588a59e3&scoped=true&"
import script from "./RuleListItems.vue?vue&type=script&lang=ts&"
export * from "./RuleListItems.vue?vue&type=script&lang=ts&"
import style0 from "./RuleListItems.vue?vue&type=style&index=0&id=588a59e3&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588a59e3",
  null
  
)

export default component.exports