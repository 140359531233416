






























































import container from '@/model/ModelContainer';
import IGuideProgramDialogState from '@/model/state/guide/IGuideProgramDialogState';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { IGuideProgramDialogSettingStorageModel } from '@/model/storage/guide/IGuideProgramDialogSettingStorageModel';
import { ISettingStorageModel } from '@/model/storage/setting/ISettingStorageModel';
import StrUtil from '@/util/StrUtil';
import Util from '@/util/Util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ProgramDialog extends Vue {
    public dialogState: IGuideProgramDialogState = container.get<IGuideProgramDialogState>('IGuideProgramDialogState');
    private setting: ISettingStorageModel = container.get<ISettingStorageModel>('ISettingStorageModel');
    private dialogSetting = container.get<IGuideProgramDialogSettingStorageModel>('IGuideProgramDialogSettingStorageModel');
    public isRemove: boolean = false;

    private snackbarState = container.get<ISnackbarState>('ISnackbarState');

    /**
     * 手動予約
     */
    public async manualReserve(): Promise<void> {
        const program = this.dialogState.getProgram();
        if (program === null) {
            return;
        }

        this.dialogState.isOpen = false;
        await Util.sleep(300);

        await Util.move(this.$router, {
            path: '/reserves/manual',
            query: {
                programId: program.id.toString(10),
            },
        });
    }

    /**
     * 手動予約編集
     */
    public async editManualReserve(): Promise<void> {
        if (this.dialogState.reserve === null) {
            return;
        }

        const reserveId = this.dialogState.reserve.reserveId;

        this.dialogState.isOpen = false;
        await Util.sleep(300);

        await Util.move(this.$router, {
            path: '/reserves/manual',
            query: {
                reserveId: reserveId.toString(10),
            },
        });
    }

    /**
     * ルール編集
     */
    public async editRule(): Promise<void> {
        if (this.dialogState.reserve === null || typeof this.dialogState.reserve.ruleId === 'undefined') {
            return;
        }

        const ruleId = this.dialogState.reserve.ruleId;

        this.dialogState.isOpen = false;
        await Util.sleep(300);

        await Util.move(this.$router, {
            path: '/search',
            query: {
                rule: ruleId.toString(10),
            },
        });
    }

    /**
     * 検索
     */
    public async search(): Promise<void> {
        this.dialogState.isOpen = false;
        await Util.sleep(300);

        // 検索用 query 生成
        const query: { [key: string]: string } = {};
        const program = this.dialogState.getProgram();
        if (program === null) {
            return;
        }

        const settingValue = this.setting.getSavedValue();

        query.keyword = StrUtil.createSearchKeyword(program.name);
        if (settingValue.isIncludeChannelIdWhenSearching === true) {
            query.channelId = program.channelId.toString(10);
        }

        if (settingValue.isIncludeGenreWhenSearching === true) {
            if (typeof program.genre1 !== 'undefined') {
                query.genre = program.genre1.toString(10);
                if (typeof program.subGenre1 !== 'undefined') {
                    query.subGenre = program.subGenre1.toString(10);
                }
            } else if (typeof program.genre2 !== 'undefined') {
                query.genre = program.genre2.toString(10);
                if (typeof program.subGenre2 !== 'undefined') {
                    query.subGenre = program.subGenre2.toString(10);
                }
            } else if (typeof program.genre3 !== 'undefined') {
                query.genre = program.genre3.toString(10);
                if (typeof program.subGenre3 !== 'undefined') {
                    query.subGenre = program.subGenre3.toString(10);
                }
            }
        }

        await Util.move(this.$router, {
            path: '/search',
            query: query,
        });
    }

    /**
     * 簡易手動予約
     */
    public async addReserve(): Promise<void> {
        try {
            await this.dialogState.addReserve();
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    text: `${this.dialogState.displayData.programName} 予約`,
                });
            }
        } catch (err) {
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    color: 'error',
                    text: `${this.dialogState.displayData.programName} 予約失敗`,
                });
            }
        }
        this.dialogState.isOpen = false;
    }

    /**
     * 予約キャンセル
     */
    public async cancelReserve(): Promise<void> {
        try {
            await this.dialogState.cancelReserve();
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    text: `${this.dialogState.displayData.programName} キャンセル`,
                });
            }
        } catch (err) {
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    color: 'error',
                    text: `${this.dialogState.displayData.programName} キャンセル失敗`,
                });
            }
        }
        this.dialogState.isOpen = false;
    }

    /**
     * 予約の除外状態解除
     */
    public async removeReserveSkip(): Promise<void> {
        try {
            await this.dialogState.removeReserveSkip();
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    text: `${this.dialogState.displayData.programName} 除外解除`,
                });
            }
        } catch (err) {
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    color: 'error',
                    text: `${this.dialogState.displayData.programName} 除外解除失敗`,
                });
            }
        }
        this.dialogState.isOpen = false;
    }

    /**
     * 予約の重複状態解除
     */
    public async removeReserveOverlap(): Promise<void> {
        try {
            if (this.dialogState.displayData !== null) {
                await this.dialogState.removeReserveOverlap();
                this.snackbarState.open({
                    text: `${this.dialogState.displayData.programName} 重複解除`,
                });
            }
        } catch (err) {
            if (this.dialogState.displayData !== null) {
                this.snackbarState.open({
                    color: 'error',
                    text: `${this.dialogState.displayData.programName} 重複解除失敗`,
                });
            }
        }
        this.dialogState.isOpen = false;
    }

    /**
     * dialog の表示状態が変更されたときに呼ばれる
     */
    @Watch('dialogState.isOpen', { immediate: true })
    public onChangeState(newState: boolean, oldState: boolean): void {
        /**
         * dialog を一度開くと v-aplication 直下に要素が追加され、
         * android 使用時に番組表のスクロールが正常にできなくなる
         * そのため一時的に isRemove を true にして要素を削除し、再度描画させている
         */
        if (newState === false && oldState === true) {
            // close
            this.dialogSetting.save();

            this.$nextTick(async () => {
                await Util.sleep(100);
                this.isRemove = true;
                this.$nextTick(() => {
                    this.isRemove = false;
                    this.dialogState.close();
                });
            });
        } else if (newState === true && oldState === false) {
            // open
            // extended の URL のリンクを貼る
            this.$nextTick(() => {
                const extended = document.getElementById('program-extended');
                if (extended !== null) {
                    let str = extended.innerHTML;
                    str = str.replace(/(http:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                    str = str.replace(/(https:\/\/[\x21-\x7e]+)/gi, "<a href='$1' target='_blank'>$1</a>");
                    extended.innerHTML = str;
                }
            });
        }
    }

    /**
     * ページ移動時
     */
    @Watch('$route', { immediate: true, deep: true })
    public onUrlChange(): void {
        // ページ移動時にダイアログが開いていたら閉じる
        if (this.dialogState.isOpen === true) {
            this.dialogState.close();
        }
    }
}
