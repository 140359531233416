export const Genre = {
    0x0: 'ニュース・報道',
    0x1: 'スポーツ',
    0x2: '情報・ワイドショー',
    0x3: 'ドラマ',
    0x4: '音楽',
    0x5: 'バラエティ',
    0x6: '映画',
    0x7: 'アニメ・特撮',
    0x8: 'ドキュメンタリー・教養',
    0x9: '劇場・公演',
    0xa: '趣味・教育',
    0xb: '福祉',
    0xc: '予備',
    0xd: '予備',
    0xe: '拡張',
    0xf: 'その他',
};

export const SubGenre = {
    0x0: {
        0x0: '定時・総合',
        0x1: '天気',
        0x2: '特集・ドキュメント',
        0x3: '政治・国会',
        0x4: '経済・市況',
        0x5: '海外・国際',
        0x6: '解説',
        0x7: '討論・会談',
        0x8: '報道特番',
        0x9: 'ローカル・地域',
        0xa: '交通',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x1: {
        0x0: 'スポーツニュース',
        0x1: '野球',
        0x2: 'サッカー',
        0x3: 'ゴルフ',
        0x4: 'その他の球技',
        0x5: '相撲・格闘技',
        0x6: 'オリンピック・国際大会',
        0x7: 'マラソン・陸上・水泳',
        0x8: 'モータースポーツ',
        0x9: 'マリン・ウィンタースポーツ',
        0xa: '競馬・公営競技',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x2: {
        0x0: '芸能・ワイドショー',
        0x1: 'ファッション',
        0x2: '暮らし・住まい',
        0x3: '健康・医療',
        0x4: 'ショッピング・通販',
        0x5: 'グルメ・料理',
        0x6: 'イベント',
        0x7: '番組紹介・お知らせ',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x3: {
        0x0: '国内ドラマ',
        0x1: '海外ドラマ',
        0x2: '時代劇',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x4: {
        0x0: '国内ロック・ポップス',
        0x1: '海外ロック・ポップス',
        0x2: 'クラシック・オペラ',
        0x3: 'ジャズ・フュージョン',
        0x4: '歌謡曲・演歌',
        0x5: 'ライブ・コンサート',
        0x6: 'ランキング・リクエスト',
        0x7: 'カラオケ・のど自慢',
        0x8: '民謡・邦楽',
        0x9: '童謡・キッズ',
        0xa: '民族音楽・ワールドミュージック',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x5: {
        0x0: 'クイズ',
        0x1: 'ゲーム',
        0x2: 'トークバラエティ',
        0x3: 'お笑い・コメディ',
        0x4: '音楽バラエティ',
        0x5: '旅バラエティ',
        0x6: '料理バラエティ',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x6: {
        0x0: '洋画',
        0x1: '邦画',
        0x2: 'アニメ',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x7: {
        0x0: '国内アニメ',
        0x1: '海外アニメ',
        0x2: '特撮',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x8: {
        0x0: '社会・時事',
        0x1: '歴史・紀行',
        0x2: '自然・動物・環境',
        0x3: '宇宙・科学・医学',
        0x4: 'カルチャー・伝統文化',
        0x5: '文学・文芸',
        0x6: 'スポーツ',
        0x7: 'ドキュメンタリー全般',
        0x8: 'インタビュー・討論',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0x9: {
        0x0: '現代劇・新劇',
        0x1: 'ミュージカル',
        0x2: 'ダンス・バレエ',
        0x3: '落語・演芸',
        0x4: '歌舞伎・古典',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0xa: {
        0x0: '旅・釣り・アウトドア',
        0x1: '園芸・ペット・手芸',
        0x2: '音楽・美術・工芸',
        0x3: '囲碁・将棋',
        0x4: '麻雀・パチンコ',
        0x5: '車・オートバイ',
        0x6: 'コンピュータ・ＴＶゲーム',
        0x7: '会話・語学',
        0x8: '幼児・小学生',
        0x9: '中学生・高校生',
        0xa: '大学生・受験',
        0xb: '生涯教育・資格',
        0xc: '教育問題',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0xb: {
        0x0: '高齢者',
        0x1: '障害者',
        0x2: '社会福祉',
        0x3: 'ボランティア',
        0x4: '手話',
        0x5: '文字(字幕)',
        0x6: '音声解説',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
    0xc: {
        0x0: '',
        0x1: '',
        0x2: '',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: '',
    },
    0xd: {
        0x0: '',
        0x1: '',
        0x2: '',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: '',
    },
    0xe: {
        0x0: 'BS/地上デジタル放送用番組付属情報',
        0x1: '広帯域 CS デジタル放送用拡張',
        0x2: '',
        0x3: 'サーバー型番組付属情報',
        0x4: 'IP 放送用番組付属情報',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: '',
    },
    0xf: {
        0x0: '',
        0x1: '',
        0x2: '',
        0x3: '',
        0x4: '',
        0x5: '',
        0x6: '',
        0x7: '',
        0x8: '',
        0x9: '',
        0xa: '',
        0xb: '',
        0xc: '',
        0xd: '',
        0xe: '',
        0xf: 'その他',
    },
};

export const VideoComponentType = {
    0x01: '480i(525i), アスペクト比4:3',
    0x02: '480i(525i), アスペクト比16:9 パンベクトルあり',
    0x03: '480i(525i), アスペクト比16:9 パンベクトルなし',
    0x04: '480i(525i), アスペクト比 > 16:9',
    0x83: '4320p, アスペクト比16:9',
    0x91: '2160p, アスペクト比4:3',
    0x92: '2160p, アスペクト比16:9 パンベクトルあり',
    0x93: '2160p, アスペクト比16:9 パンベクトルなし',
    0x94: '2160p, アスペクト比 > 16:9',
    0xa1: '480p(525p), アスペクト比4:3',
    0xa2: '480p(525p), アスペクト比16:9 パンベクトルあり',
    0xa3: '480p(525p), アスペクト比16:9 パンベクトルなし',
    0xa4: '480p(525p), アスペクト比 > 16:9',
    0xb1: '1080i(1125i), アスペクト比4:3',
    0xb2: '1080i(1125i), アスペクト比16:9 パンベクトルあり',
    0xb3: '1080i(1125i), アスペクト比16:9 パンベクトルなし',
    0xb4: '1080i(1125i), アスペクト比 > 16:9',
    0xc1: '720p(750p), アスペクト比4:3',
    0xc2: '720p(750p), アスペクト比16:9 パンベクトルあり',
    0xc3: '720p(750p), アスペクト比16:9 パンベクトルなし',
    0xc4: '720p(750p), アスペクト比 > 16:9',
    0xd1: '240p アスペクト比4:3',
    0xd2: '240p アスペクト比16:9 パンベクトルあり',
    0xd3: '240p アスペクト比16:9 パンベクトルなし',
    0xd4: '240p アスペクト比 > 16:9',
    0xe1: '1080p(1125p), アスペクト比4:3',
    0xe2: '1080p(1125p), アスペクト比16:9 パンベクトルあり',
    0xe3: '1080p(1125p), アスペクト比16:9 パンベクトルなし',
    0xe4: '1080p(1125p), アスペクト比 > 16:9',
    0xf1: '180p アスペクト比4:3',
    0xf2: '180p アスペクト比16:9 パンベクトルあり',
    0xf3: '180p アスペクト比16:9 パンベクトルなし',
    0xf4: '180p アスペクト比 > 16:9',
};

export const AudioComponentType = {
    0b00000: '将来使用のためリザーブ',
    0b00001: '1/0モード(シングルモノ)',
    0b00010: '1/0 + 1/0モード(デュアルモノ)',
    0b00011: '2/0モード(ステレオ)',
    0b00100: '2/1モード',
    0b00101: '3/0モード',
    0b00110: '2/2モード',
    0b00111: '3/1モード',
    0b01000: '3/2モード',
    0b01001: '3/2 + LFEモード(3/2.1モード)',
    0b01010: '3/3.1モード',
    0b01011: '2/0/0-2/0/2-0.1モード',
    0b01100: '5/2.1モード',
    0b01101: '3/2/2.1モード',
    0b01110: '2/0/0-3/0/2-0.1モード',
    0b01111: '0/2/0-3/0/2-0.1モード',
    0b10000: '2/0/0-3/2/3-0.2モード',
    0b10001: '3/3/3-5/2/3-3/0/0.2モード',
    0b10010: '将来使用のためリザーブ',
    0b10011: '将来使用のためリザーブ',
    0b10100: '将来使用のためリザーブ',
    0b10101: '将来使用のためリザーブ',
    0b10110: '将来使用のためリザーブ',
    0b10111: '将来使用のためリザーブ',
    0b11000: '将来使用のためリザーブ',
    0b11001: '将来使用のためリザーブ',
    0b11010: '将来使用のためリザーブ',
    0b11011: '将来使用のためリザーブ',
    0b11100: '将来使用のためリザーブ',
    0b11101: '将来使用のためリザーブ',
    0b11110: '将来使用のためリザーブ',
    0b11111: '将来使用のためリザーブ',
};

export const AudioSamplingRate = {
    16000: '16kHz',
    22050: '22.05kHz',
    24000: '24kHz',
    32000: '32kHz',
    44100: '44.1kHz',
    48000: '48kHz',
};
