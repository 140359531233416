import { render, staticRenderFns } from "./MobilePagination.vue?vue&type=template&id=578bf7cf&scoped=true&"
import script from "./MobilePagination.vue?vue&type=script&lang=ts&"
export * from "./MobilePagination.vue?vue&type=script&lang=ts&"
import style0 from "./MobilePagination.vue?vue&type=style&index=0&id=578bf7cf&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578bf7cf",
  null
  
)

export default component.exports