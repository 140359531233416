







































import RuleDeleteDialog from '@/components/rules/RuleDeleteDialog.vue';
import { RuleStateData } from '@/model/state/rule/IRuleState';
import Util from '@/util/Util';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        RuleDeleteDialog,
    },
})
export default class RuleItemMenu extends Vue {
    @Prop({ required: true })
    public ruleItem!: RuleStateData;

    public isOpenDeleteDialog: boolean = false;

    public onRecorded(): void {
        Util.move(this.$router, {
            path: '/recorded',
            query: {
                ruleId: this.ruleItem.display.id.toString(10),
            },
        });
    }

    public onEdit(): void {
        Util.move(this.$router, {
            path: '/search',
            query: {
                rule: this.ruleItem.display.id.toString(10),
            },
        });
    }

    public async openDeleteDialog(): Promise<void> {
        await Util.sleep(300);
        this.isOpenDeleteDialog = true;
    }
}
