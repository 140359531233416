










































































































































































































































































































































import TitleBar from '@/components/titleBar/TitleBar.vue';
import container from '@/model/ModelContainer';
import IScrollPositionState from '@/model/state/IScrollPositionState';
import INavigationState from '@/model/state/navigation/INavigationState';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { ISettingStorageModel, GuideViewMode } from '@/model/storage/setting/ISettingStorageModel';
import { Component, Vue, Watch } from 'vue-property-decorator';
import IColorThemeState from '@/model/state/IColorThemeState';
import Mpegts from 'mpegts.js';

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteLeave']);

interface GuideModeItem {
    text: string;
    value: GuideViewMode;
}

interface SelectItem {
    text: string;
    value: number;
}

@Component({
    components: {
        TitleBar,
    },
})
export default class Settings extends Vue {
    public isShow: boolean = false;
    public storageModel: ISettingStorageModel = container.get<ISettingStorageModel>('ISettingStorageModel');

    private navigationState: INavigationState = container.get<INavigationState>('INavigationState');
    private scrollState: IScrollPositionState = container.get<IScrollPositionState>('IScrollPositionState');
    private snackbarState: ISnackbarState = container.get<ISnackbarState>('ISnackbarState');
    private colorThemeState: IColorThemeState = container.get<IColorThemeState>('IColorThemeState');

    public readonly guideModeItems: GuideModeItem[] = [
        {
            text: '逐次',
            value: 'sequential',
        },
        {
            text: '最小',
            value: 'minimum',
        },
        {
            text: 'すべて',
            value: 'all',
        },
    ];

    public guideLengthItems: SelectItem[] = [];
    public reservesLengthItems: SelectItem[] = [];
    public recordingLengthItems: SelectItem[] = [];
    public recordedLengthItems: SelectItem[] = [];
    public searchLengthItems: SelectItem[] = [];
    public rulesLengthItems: SelectItem[] = [];

    get shouldUseOSColorTheme(): boolean {
        return this.storageModel.tmp.shouldUseOSColorTheme;
    }

    set shouldUseOSColorTheme(value: boolean) {
        this.storageModel.tmp.shouldUseOSColorTheme = value;
        if (value) {
            this.isForceDarkTheme = this.colorThemeState.isTmpDarkTheme();
        }
    }

    get isForceDarkTheme(): boolean {
        return this.storageModel.tmp.isForceDarkTheme;
    }

    set isForceDarkTheme(value: boolean) {
        this.storageModel.tmp.isForceDarkTheme = value;
        this.$vuetify.theme.dark = value;
    }

    get isSupportedMpegts(): boolean {
        return Mpegts.isSupported();
    }

    constructor() {
        super();

        this.isForceDarkTheme = this.colorThemeState.isTmpDarkTheme();

        for (let i = 1; i <= 24; i++) {
            this.guideLengthItems.push({
                text: i.toString(10),
                value: i,
            });
        }

        for (let i = 1; i <= 100; i++) {
            const item: SelectItem = {
                text: i.toString(10),
                value: i,
            };
            this.reservesLengthItems.push(item);
            this.recordingLengthItems.push(item);
            this.recordedLengthItems.push(item);
            this.rulesLengthItems.push(item);
        }

        for (let i = 50; i <= 600; i += 50) {
            const item: SelectItem = {
                text: i.toString(10),
                value: i,
            };
            this.searchLengthItems.push(item);
        }
    }

    public beforeDestroy(): void {
        this.isShow = false;
    }

    public destroyed(): void {
        // ページから移動するときに tmp をリセット
        this.storageModel.resetTmpValue();
        this.$vuetify.theme.dark = this.colorThemeState.isDarkTheme();
    }

    /**
     * setting の tmp をデフォルト値へリセットする
     */
    public reset(): void {
        this.storageModel.tmp = this.storageModel.getDefaultValue();
        this.$vuetify.theme.dark = this.colorThemeState.isDarkTheme();
    }

    /**
     * tmp の値を保存する
     */
    public save(): void {
        this.storageModel.save();
        this.navigationState.updateItems(this.$route);

        this.snackbarState.open({
            text: '保存されました',
            color: 'success',
        });
    }

    @Watch('$route', { immediate: true, deep: true })
    public onUrlChange(): void {
        this.$nextTick(() => {
            this.isShow = true;

            this.$nextTick(async () => {
                // スクロール位置復元を許可
                await this.scrollState.emitDoneGetData();
            });
        });
    }
}
