





































































































































































































































import SearchGenreOption from '@/components/search/SearchGenreOption.vue';
import SearchOptionRow from '@/components/search/SearchOptionRow.vue';
import container from '@/model/ModelContainer';
import ISearchState from '@/model/state/search/ISearchState';
import VuetifyUtil from '@/util/VuetifyUtil';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        SearchOptionRow,
        SearchGenreOption,
    },
})
export default class SearchOption extends Vue {
    public searchState: ISearchState = container.get<ISearchState>('ISearchState');

    public isOpenStartTimepicker: boolean = false;
    public isOpenEndTimepicker: boolean = false;

    public mounted(): void {
        if (typeof this.$refs.keyword !== 'undefined') {
            VuetifyUtil.focusTextFiled(this.$refs.keyword as Vue);
        }
    }

    public onKeywordEnter(e: Event): void {
        // フォーカスを外す
        if (e.target !== null) {
            (e.target as HTMLElement).blur();
        }

        this.onClickSearch();
    }

    public onClickSearch(): void {
        this.$emit('search');
    }

    public onClickClear(): void {
        this.$emit('clear');
    }
}
