









































import Util from '@/util/Util';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class RecordedMainMenu extends Vue {
    public isOpened: boolean = false;

    public edit(): void {
        this.$emit('edit');
    }

    public cleanup(): void {
        this.$emit('cleanup');
    }

    public async upload(): Promise<void> {
        await Util.sleep(200);
        await Util.move(this.$router, {
            path: '/recorded/upload',
        });
    }

    public onClickMenuBackground(e: Event): boolean {
        e.stopPropagation();

        return false;
    }
}
