


























import EncodeCancelDialog from '@/components/encode/EncodeCancelDialog.vue';
import { EncodeInfoDisplayItem } from '@/model/state/encode/IEncodeState';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({
    components: {
        EncodeCancelDialog,
    },
})
export default class EncodeSmallCard extends Vue {
    @Prop({ required: true })
    public item!: EncodeInfoDisplayItem;

    @Prop({ required: true })
    public isEditMode!: boolean;

    public isOpenCancelDialog: boolean = false;

    public openCancelDialog(): void {
        this.isOpenCancelDialog = true;
    }

    public clickItem(): void {
        if (this.isEditMode === false) {
            return;
        }

        this.$emit('selected', this.item.encodeItem.id);
    }
}
