















import SendVideoFileToKodiDialog from '@/components/recorded/detail/SendVideoFileToKodiDialog.vue';
import container from '@/model/ModelContainer';
import IServerConfigModel from '@/model/serverConfig/IServerConfigModel';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../../api';

@Component({
    components: {
        SendVideoFileToKodiDialog,
    },
})
export default class RecordedDetailKodiButton extends Vue {
    @Prop({ required: true })
    public recordedItem!: apid.RecordedItem;

    @Prop({ required: true })
    public videoFiles!: apid.VideoFile[];

    public serverConfig: IServerConfigModel = container.get<IServerConfigModel>('IServerConfigModel');
    public isOpenKodiDialog: boolean = false;

    public openKodiDialog(): void {
        this.isOpenKodiDialog = true;
    }
}
