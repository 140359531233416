import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"300","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-4"},[_c('div',{staticClass:"text--primary"},[_vm._v(_vm._s(_vm.ruleItem.display.keyword)+" を削除しますか?")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogModel = false}}},[_vm._v("キャンセル")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteRule}},[_vm._v("削除")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }