import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-1",attrs:{"color":"primary"}},on),[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v(_vm._s(_vm.button))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1)]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c(VCard,{attrs:{"max-width":"200"}},[_c('div',{staticClass:"pa-2 d-flex flex-wrap"},_vm._l((_vm.videoFiles),function(video){return _c(VBtn,{key:video.id,staticClass:"ma-1",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.play(video)}}},[_vm._v(" "+_vm._s(video.name)+" ")])}),1)])],1),(_vm.isOpened === true)?_c('div',{staticClass:"menu-background",on:{"click":_vm.onClickMenuBackground}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }