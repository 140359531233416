








































import container from '@/model/ModelContainer';
import IAddEncodeState from '@/model/state/encode/IAddEncodeState';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { IAddEncodeSettingStorageModel } from '@/model/storage/encode/IAddEncodeSettingStorageModel';
import Util from '@/util/Util';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({})
export default class AddEncodeDialog extends Vue {
    @Prop({ required: true })
    public recordedItem!: apid.RecordedItem;

    @Prop({ required: true })
    public isOpen!: boolean;

    public addEncodeState = container.get<IAddEncodeState>('IAddEncodeState');
    public setting: IAddEncodeSettingStorageModel = container.get<IAddEncodeSettingStorageModel>('IAddEncodeSettingStorageModel');
    private snackbarState: ISnackbarState = container.get<ISnackbarState>('ISnackbarState');

    public isRemove: boolean = false;

    /**
     * Prop で受け取った isOpen を直接は書き換えられないので
     * getter, setter を用意する
     */
    get dialogModel(): boolean {
        return this.isOpen;
    }
    set dialogModel(value: boolean) {
        this.$emit('update:isOpen', value);
    }

    public cancel(): void {
        this.dialogModel = false;
    }

    public async add(): Promise<void> {
        this.dialogModel = false;

        try {
            await this.addEncodeState.addEncode();
            this.snackbarState.open({
                color: 'success',
                text: 'エンコード追加',
            });
        } catch (err) {
            console.error(err);
            this.snackbarState.open({
                color: 'error',
                text: 'エンコード追加に失敗しました',
            });
        }
    }

    @Watch('isOpen', { immediate: true })
    public onChangeState(newState: boolean, oldState: boolean): void {
        if (newState === true && !!oldState === false) {
            const settingValue = this.setting.getSavedValue();
            this.addEncodeState.init(
                this.recordedItem.id,
                typeof this.recordedItem.videoFiles === 'undefined' ? [] : this.recordedItem.videoFiles,
                settingValue.encodeMode,
                settingValue.parentDirectory,
            );
        } else if (newState === false && oldState === true) {
            // close
            this.setting.tmp.encodeMode = this.addEncodeState.encodeMode;
            this.setting.tmp.parentDirectory = this.addEncodeState.parentDirectory;
            this.setting.save();

            this.$nextTick(async () => {
                await Util.sleep(100);
                // dialog close アニメーションが終わったら要素を削除する
                this.isRemove = true;
                this.$nextTick(() => {
                    this.isRemove = false;
                });
            });
        }
    }
}
