





















import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../../api';

@Component({})
export default class RecordedDetailPlayButton extends Vue {
    @Prop({ required: true })
    public title!: string;

    @Prop({ required: true })
    public button!: string;

    @Prop({ required: true })
    public videoFiles!: apid.VideoFile[];

    public isOpened: boolean = false;

    public play(video: apid.VideoFile): void {
        this.$emit('play', video);
    }

    public onClickMenuBackground(e: Event): boolean {
        e.stopPropagation();

        return false;
    }
}
