import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guide-genre-setting-dialog"},[(_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"500","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-4"},_vm._l((_vm.genreItems),function(item){return _c('div',{key:item.id,staticClass:"d-flex align-center",staticStyle:{"height":"48px"}},[_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(item.text))]),_c(VSpacer),_c(VSwitch,{model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)}),0),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialogModel = false}}},[_vm._v("キャンセル")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.update}},[_vm._v("更新")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }