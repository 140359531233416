















import container from '@/model/ModelContainer';
import IGuideProgramDialogState from '@/model/state/guide/IGuideProgramDialogState';
import { SearchResultItem } from '@/model/state/search/ISearchState';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({})
export default class SearchResultCard extends Vue {
    @Prop({ required: true })
    public program!: SearchResultItem;

    private dialogState: IGuideProgramDialogState = container.get<IGuideProgramDialogState>('IGuideProgramDialogState');

    public openDetail(): void {
        this.dialogState.open({
            channel: this.program.channel,
            program: this.program.program,
            reserve: this.program.reserve,
        });
    }
}
