
























import RuleItemMenu from '@/components/rules/RuleItemMenu.vue';
import { RuleStateData, RuleStateDisplayData } from '@/model/state/rule/IRuleState';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        RuleItemMenu,
    },
})
export default class RuleListItems extends Vue {
    @Prop({ required: true })
    public items!: RuleStateData[];

    @Prop({ required: true })
    public isEditMode!: boolean;

    /**
     * ルールの有効、無効を変える
     */
    public changeState(item: RuleStateData): void {
        this.$emit('changeState', item);
    }

    /**
     * item 選択
     */
    public selectItem(item: RuleStateData): void {
        if (this.isEditMode === false) {
            return;
        }

        this.$emit('selected', item.display.id);
    }
}
