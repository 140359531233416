









import container from '@/model/ModelContainer';
import IGuideState from '@/model/state/guide/IGuideState';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class TimeScale extends Vue {
    public guideState: IGuideState = container.get<IGuideState>('IGuideState');

    /**
     * item class
     * @return {}
     */
    public getItemClass(time: number): any {
        const result: any = {};
        result[`time-${time.toString(10)}`] = true;

        return result;
    }
}
