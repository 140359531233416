
























import Util from '@/util/Util';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class RuleSearchMenu extends Vue {
    public isOpen: boolean = false;
    public keyword: string | null = '';

    public onCancel(): void {
        this.isOpen = false;
    }

    public onSearch(): void {
        this.isOpen = false;

        this.$nextTick(async () => {
            await Util.sleep(300);

            const searchQuery: any = {};
            if (typeof this.keyword !== 'undefined' && this.keyword !== null && this.keyword.length > 0) {
                searchQuery.keyword = this.keyword;
            }

            await Util.move(this.$router, {
                path: '/rule',
                query: searchQuery,
            });
        });
    }

    public onClickMenuBackground(e: Event): boolean {
        e.stopPropagation();

        return false;
    }

    /**
     * ページ移動時
     */
    @Watch('$route', { immediate: true, deep: true })
    public onUrlChange(): void {
        this.isOpen = false;
    }

    @Watch('isOpen', { immediate: true })
    public onChangeState(newState: boolean, oldState: boolean): void {
        if (newState === true && oldState === false) {
            // query から値をセット
            this.keyword = typeof this.$route.query.keyword === 'string' ? this.$route.query.keyword : '';
        }
    }
}
