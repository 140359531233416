








































































































































































































import TitleBar from '@/components/titleBar/TitleBar.vue';
import container from '@/model/ModelContainer';
import IScrollPositionState from '@/model/state/IScrollPositionState';
import ISnackbarState from '@/model/state/snackbar/ISnackbarState';
import { IGuideSizeSettingStorageModel } from '@/model/storage/guide/IGuideSizeSettingStorageModel';
import { ISettingStorageModel, GuideViewMode } from '@/model/storage/setting/ISettingStorageModel';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteLeave']);

interface SelectItem {
    text: string;
    value: number;
}

@Component({
    components: {
        TitleBar,
    },
})
export default class GuideSizeSetting extends Vue {
    public isShow: boolean = false;
    public storageModel = container.get<IGuideSizeSettingStorageModel>('IGuideSizeSettingStorageModel');

    public channelHeightItems: number[] = [];
    public channelWidthItems: number[] = [];
    public timeHeightItems: number[] = [];
    public timeWidthItems: number[] = [];
    public fontSizeItems: SelectItem[] = [];

    private scrollState: IScrollPositionState = container.get<IScrollPositionState>('IScrollPositionState');
    private snackbarState: ISnackbarState = container.get<ISnackbarState>('ISnackbarState');

    constructor() {
        super();

        for (let i = 0; i <= 600; i += 10) {
            this.channelWidthItems.push(i);
        }

        for (let i = 10; i <= 400; i += 10) {
            this.timeHeightItems.push(i);
        }

        for (let i = 10; i <= 100; i += 10) {
            this.channelHeightItems.push(i);
            this.timeWidthItems.push(i);
        }

        for (let i = 5; i <= 400; i += 5) {
            const value = i / 10;
            this.fontSizeItems.push({
                text: value.toFixed(1),
                value: value,
            });
        }
    }

    public beforeDestroy(): void {
        this.isShow = false;
    }

    public reset(): void {
        this.storageModel.tmp = this.storageModel.getDefaultValue();
    }

    public save(): void {
        this.storageModel.save();

        this.snackbarState.open({
            text: '保存されました',
            color: 'success',
        });
    }

    @Watch('$route', { immediate: true, deep: true })
    public onUrlChange(): void {
        this.$nextTick(() => {
            this.isShow = true;

            this.$nextTick(async () => {
                // スクロール位置復元を許可
                await this.scrollState.emitDoneGetData();
            });
        });
    }
}
