




































import container from '@/model/ModelContainer';
import ISearchState, { GenreItem, SelectorItem } from '@/model/state/search/ISearchState';
import { cloneDeep } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({})
export default class SearchGenreOption extends Vue {
    public searchState: ISearchState = container.get<ISearchState>('ISearchState');

    get genreSelectionItems(): SelectorItem[] {
        const result = this.searchState.getGenreItems().map(g => {
            return {
                text: g.name,
                value: g.value,
            };
        });

        result.unshift({
            text: 'すべて',
            value: -1,
        });

        return result;
    }

    get genreItems(): GenreItem[] {
        if (this.searchState.genreSelect === -1) {
            return this.searchState.getGenreItems();
        }

        return this.searchState.getGenreItems().filter(g => {
            return g.value === this.searchState.genreSelect;
        });
    }

    /**
     * ジャンル絞り込み状態変更
     */
    public onChangeGenreSelector(): void {
        if (typeof this.$refs.card === 'undefined') {
            return;
        }
        (this.$refs.card as any).$el.scrollTop = 0;
    }

    /**
     * ジャンル選択処理
     * @param genre: apid.ProgramGenreLv1
     */
    public onClickGenre(genre: apid.ProgramGenreLv1): void {
        this.searchState.onClickGenre(genre);
        this.updateGenreView();
    }

    /**
     * サブジャンル選択処理
     * @param genre: apid.ProgramGenreLv1
     * @param subGenre: apid.ProgramGenreLv2
     */
    public onClickSubGenre(genre: apid.ProgramGenreLv1, subGenre: apid.ProgramGenreLv2): void {
        this.searchState.onClickSubGenre(genre, subGenre);
        this.updateGenreView();
    }

    /**
     * ジャンル選択クリア処理
     */
    public cleatGenres(): void {
        this.searchState.clearGenres();
        this.updateGenreView();
    }

    /**
     * searchState.searchOption.genres の更新を vue に伝える
     */
    private updateGenreView(): void {
        if (this.searchState.searchOption === null) {
            return;
        }

        const newGenres = cloneDeep(this.searchState.searchOption.genres);
        this.$set(this.searchState.searchOption, 'genres', newGenres);
    }
}
