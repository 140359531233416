import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRemove === false)?_c(VDialog,{attrs:{"max-width":"400","scrollable":""},model:{value:(_vm.dialogModel),callback:function ($$v) {_vm.dialogModel=$$v},expression:"dialogModel"}},[_c(VCard,[_c('div',{staticClass:"pa-3 pt-4 pb-0 add-encode"},[_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.recordedItem.name))]),_c(VSelect,{staticClass:"preset",attrs:{"items":_vm.dialogState.getHosts(),"label":"kodi host","menu-props":{ auto: true }},model:{value:(_vm.dialogState.hostName),callback:function ($$v) {_vm.$set(_vm.dialogState, "hostName", $$v)},expression:"dialogState.hostName"}}),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.videoFiles),function(video){return _c(VBtn,{key:video.id,staticClass:"ma-1",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.send(video.id)}}},[_vm._v(" "+_vm._s(video.name)+" ")])}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.close}},[_vm._v("閉じる")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }