



























import container from '@/model/ModelContainer';
import IGuideProgramDialogState, { ProgramDialogOpenOption } from '@/model/state/guide/IGuideProgramDialogState';
import { ReserveStateItemIndex } from '@/model/state/guide/IGuideReserveUtil';
import IOnAirSelectStreamState from '@/model/state/onair/IOnAirSelectStreamState';
import { OnAirDisplayData } from '@/model/state/onair/IOnAirState';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as apid from '../../../../api';

@Component({})
export default class OnAirCard extends Vue {
    @Prop({ required: true })
    public items!: OnAirDisplayData[];

    @Prop({ required: true })
    public reserveIndex!: ReserveStateItemIndex;

    private streamSelectDialog: IOnAirSelectStreamState = container.get<IOnAirSelectStreamState>('IOnAirSelectStreamState');
    private dialogState: IGuideProgramDialogState = container.get<IGuideProgramDialogState>('IGuideProgramDialogState');

    public openGuideProgramDialog(schedule: apid.Schedule, e: Event): void {
        e.stopPropagation();

        const option: ProgramDialogOpenOption = {
            channel: schedule.channel,
            program: schedule.programs[0],
        };
        if (typeof this.reserveIndex[schedule.programs[0].id] !== 'undefined') {
            option.reserve = {
                type: this.reserveIndex[schedule.programs[0].id].type,
                reserveId: this.reserveIndex[schedule.programs[0].id].item.reserveId,
                ruleId: this.reserveIndex[schedule.programs[0].id].item.ruleId,
            };
        }

        this.dialogState.open(option);
    }

    /**
     * ストリーム選択ダイアログを開く
     * @param channelItem: apid.ScheduleChannleItem
     */
    public openStreamSelector(channelItem: apid.ScheduleChannleItem): void {
        this.streamSelectDialog.open(channelItem);
    }
}
